.footer {
	position: unset;
}

button#page-header-notifications-dropdown {
	margin-top: 6px;
	margin-right: 15px;
}

.btn.btn-sm i {
	position: relative;
	top: 2px;
}

.btn.btn-sm i.fa {
	top: 0px;
	left: 0.5px;
}

.btn {
	font-size: 0.8rem;
}

.ant-message {
	z-index: 1090;
}

.block {
	display: block;
}

@media (min-width: 1200px) {

	body[data-layout="horizontal"] .container-fluid,
	body[data-layout="horizontal"] .container-sm,
	body[data-layout="horizontal"] .container-md,
	body[data-layout="horizontal"] .container-lg,
	body[data-layout="horizontal"] .container-xl,
	body[data-layout="horizontal"] .navbar-header {
		 max-width: 98%;
	}
}

.bold {
	font-weight: bold;
}

.page-content {
	min-height: 83vh;
}

.ant-pagination span.anticon.anticon-right {
	position: relative;
	top: -4px;
}

.ant-pagination span.anticon.anticon-left {
	position: relative;
	top: -3px;
}

.text-link {
	cursor: pointer;
	color: #00D385;
}

.hide {
	display: none !important;
}

.topbar-btn {
	padding: 8px 20px;
	font-size: 14px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
	height: 40px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	padding: 4px 15px;
}

.site-navigation-steps {
	border-radius: 50px;
	background-color: #e1e1f3;
}

.site-navigation-steps .ant-steps-navigation {
	padding-top: 10px;
}

.site-navigation-steps .card-body {
	padding: 0px 15px;
}

.site-navigation-steps .ant-steps-navigation .ant-steps-item::before {
	background-color: #04004D;
	height: 2px;
}

.site-navigation-steps .ant-steps-navigation .ant-steps-item::after {
	top: 55%;
}

.site-navigation-steps .ant-steps-navigation .ant-steps-item-container {
	padding-bottom: 10px;
}

span.anticon.anticon-close {
	position: relative;
	top: -2px;
	left: 2px;
	margin-left: 10px;
}

.ant-tag,
.ant-tag a,
.ant-tag a:hover {
	padding: 4px 9px;
	font-size: 14px;
	background-color: #dadaea !important;
	color: #04004d;
	margin-bottom: 7px;
}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
	padding: 8px 10px;
}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label {
	color: #777;
	width: 45%;
}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content a {
	color: #333;
}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content a:hover {
	color: #00D385;
	text-decoration: underline !important;
}

button.ant-switch.ant-switch-small {
	position: relative;
	top: -1px;
}

a.ml-1.mt-auto {
	width: 100%;
}

.bg-light-white {
	background-color: #ffffff80 !important;
}

.bg-light-gray {
	background-color: #fafafa !important;
}

.activities-timeline .ant-timeline-item-head {
	top: 15px;
}

li.ant-timeline-item.activities-timeline {
	padding-bottom: 0px;
}

.ant-steps-item-title {
	font-size: 15px !important;
	font-weight: 500;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
	color: #7c79a6;
}

.sc-fzpans {
	background-color: #ececf7 !important;
	padding: 12px 10px 5px !important;
}

.borad-width {
	min-width: 300px;
}

.modal-open {
	overflow: scroll !important;
}

@media(max-width: 768px) {
	.hide-md {
		 display: none !important;
	}
}

@media(max-width: 540px) {
	.hide-sm {
		 display: none !important;
	}
}

@media (max-width: 1024px) {
	.borad-width {
		 min-width: 250px;
	}
}

.task-box.card {
	width: 310px !important;
}

.border-left-divider {
	border-left: 1px dashed #ccc;
}
.border-bottom-divider {
	border-bottom: 1px solid #eee;
}

.flat-form-control .form-control {
	border-radius: 0;
}

.ant-picker-preset .ant-tag.ant-tag-blue {
	background-color: #fff !important;
	border-color: #04004D;
	color: #04004D;
}

.anticon svg {
	position: relative;
	top: -3px;
}

.border-radius-none {
	border-radius: 0px;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
	font-size: 11px;
}


.sketch-picker {
	position: absolute;
	z-index: 1;
}


.react-datepicker-wrapper {
	width: 100% !important;
}

.ReactModal__Overlay {
	z-index: 1001 !important;
}

.chat-conversation .right .conversation-list {
	margin-right: 15px;
}

a:hover {
	cursor: pointer;
}

.rating-container {
	background-color: transparent !important;
}

.input-group-append {
	z-index: 0;
}

.noti-icon .badge {
    left: 23px;
}

.dataTables_filter,
.dataTables_paginate {
    float: right;
}

.list-top-bar button.btn {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	font-size: 14px;
}
.list-top-bar div.float-right {
	min-width: 550px;
}

.full-width {
	width: 100%;
}

.fs-14 { font-size: 14px; }
.fs-15 { font-size: 15px; }
.fs-16 { font-size: 16px; }
.fs-17 { font-size: 17px; }
.fs-18 { font-size: 18px; }
.fs-19 { font-size: 19px; }
.fs-20 { font-size: 20px; }
.fs-21 { font-size: 21px; }
.fs-22 { font-size: 22px; }
.fs-23 { font-size: 23px; }
.fs-24 { font-size: 24px; }
.fs-25 { font-size: 25px; }

.no-box-shadow { box-shadow: none; }
@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// RTL Mode
// @import "./bootstrap.scss";
// @import "./app-rtl.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";
@import 'react-big-calendar/lib/sass/styles';

#sidebar-menu {
    ul {
        li {
            a {
                i {
                    min-width: 2rem;
                }
            }
        }
    }
}

.rdw-editor-main {
    border: 1px solid $gray-300;
    height: 239px;
}

.dz-message {
    text-align: center;
    padding: 30px;
}

// kanban board
.fcYNFc,
.sc-AxmLO.gmtmqV {
    background-color: $card-bg !important;
    margin: 0 20px 0 0 !important;
}

.task-box {
    border: 1px solid $border-color;
}

.input-color {
    color: $input-color !important;
}
.rangeslider__fill {
    background-color: $primary !important;
}

.external-event {
	&:hover {
		 cursor: pointer;
	}
}